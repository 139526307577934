export const SOFTDENT_CLOUD = 'Softdent Cloud'
export const MOCKPMS = 'MockPMS'
export const ORTHOTRAC_CLOUD = 'OrthoTrac Cloud'
export const CORNERSTONE = 'Cornerstone'
export const ADVANTAGE = 'Advantage'
export const CLIENTRAX = 'ClienTrax'
export const COMPLETE_CLINIC = 'Complete Clinic'
export const DENTRIXG4_RAW = 'DentrixG4Raw'
export const EASY_DENTAL_RAW = 'Easy Dental Raw'
export const DENTRIXG5 = 'DentrixG5'
export const DSN = 'DSN Dental Exec'
export const DATAPOINT = 'Datapoint'
export const EAGLESOFT18 = 'Eaglesoft18'
export const ECW = 'EClinicalWorks'
export const PRACTICE_FUSION = 'Practice Fusion'
export const EDDSON = 'Eddson'
export const EYEFINITY = 'Eyefinity'
export const IMPROMED = 'Impromed'
export const INTRAVET = 'Intravet'
export const MACPRACTICE = 'Macpractice'
export const MAXIMEYES = 'Maximeyes'
export const MINDBODY = 'Mindbody'
export const MINDBODYV6 = 'MindBody V6'
export const MODMED = 'ModMed'
export const EMA = 'EMA'
export const NAVETOR = 'Navetor'
export const NEXTECH = 'Nextech'
export const OASYS = 'Oasys'
export const ODLINK = 'ODLink'
export const OFFICEMATE = 'Officemate'
export const OMSVISION = 'OMSVision'
export const OPENDENTAL = 'Open Dental'
export const OPENDENTAL_CLOUD = 'Open Dental Cloud'
export const OPENDENTAL_LOCAL = 'Open Dental Cloud Local'
export const ORTHOTRAC = 'OrthoTrac'
export const DOLPHIN = 'Dolphin'
export const DOLPHINCLOUD = 'DolphinCloud'
export const DOLPHIN_BLUE = 'DolphinBlue'
export const DR_CHRONO = 'Dr Chrono'
export const EAGLESOFT16 = 'Eaglesoft16'
export const ORTHOTRAC_CLOUD_V2 = 'OrthoTrac Cloud V2'
export const PATTERSON_FUSE = 'Patterson Fuse'
export const PRACTICE_DIRECTOR = 'Practice Director'
export const PRACTICE_WORKS = 'PracticeWorks'
export const PROVET_CLOUD = 'ProvetCloud'
export const CSV = 'CSV'
export const CUSTOM_CONTACT = 'Custom Contact'
export const UNKNOWN = 'Unknown'
export const ABELDENT = 'Abeldent'
export const ADVANCEDMD = 'AdvancedMD'
export const ATHENAHEALTH = 'AthenaHealth'
export const AVIMARK = 'Avimark'
export const CLOUD9 = 'Cloud 9'
export const COMPULINK = 'Compulink'
export const DVM_MANAGER = 'DVM Manager'
export const DVMAX = 'DVMAX'
export const HVMS = 'HVMS'
export const STRINGSOFT = 'StringSoft'
export const TRAKNET_CLOUD = 'Traknet Cloud'
export const TRIPLE_CROWN = 'Triple Crown'
export const VIA = 'VIA'
export const CURVE = 'Curve'
export const SHEPHERD = 'Shepherd'
export const EZYVET = 'EzyVet'
export const DENTRIXG4 = 'DentrixG4'
export const EASY_DENTAL = 'Easy Dental'
export const EMR4D = 'EMR4D'
export const EVETPRACTICE = 'EVet Practice'
export const PRACTICEWORKS_CLOUD_V2 = 'PRACTICEWORKS Cloud V2'
export const QUICKBOOKS_ONLINE = 'Quickbooks Online'
export const VTECH_PLATINUM = 'vTech Platinum'
export const CRYSTAL_PM = 'Crystal'
export const DAYSMARTVET = 'Day Smart Vet'
export const DENTICON = 'Denticon'
export const PLANETDDS_DENTICON = 'PlanetDDS Denticon'
export const DENTIMAX = 'Dentimax'
export const DENTRIX_ASCEND = 'Dentrix Ascend'
export const DENTRIX_ENTERPRISE = 'Dentrix Enterprise'
export const NEXTECH_PRACTICE = 'Nextech Practice'
export const NEXTGEN = 'Nextgen'
export const ORTHO2 = 'Ortho2'
export const REVOLUTION = 'Revolution'
export const SOFTDENT16 = 'SoftDent16'
export const SOFTDENT_CLOUD_V2 = 'Softdent Cloud V2'
export const SYCLE = 'Sycle'
export const TOTALMD = 'TotalMD'
export const TRAKNET = 'Traknet'
export const UPRISE = 'Uprise'
export const WEAVEPMS = 'WeavePMS'
export const WINOMS = 'WinOMS'
export const WINOMS_CLOUD_V2 = 'WinOMS Cloud V2'
export const BOOKER = 'Booker'
export const PRACTICE_WORKS_CLOUD = 'PracticeWorks Cloud'
export const SENSEI = 'Sensei'
export const WINOMS_CLOUD = 'WinOMS Cloud'
export const VERADIGM = 'Veradigm'
export const VERADIGM_CLOUD = 'VeradigmCloud'
export const INFINITE_VT = 'Infinite VT'
export const PROMPT = 'Prompt'
